.ring-animation {
    animation: 0.6s ease-in-out ring;
    transform-origin: 50% 4px 0px;
}
.icon-bell-alert-offer,
.icon-bell-alert-offer-reduce {
    pointer-events: none;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(20deg);
    }
    25% {
        transform: rotate(-20deg);
    }
    40% {
        transform: rotate(15deg);
    }
    55% {
        transform: rotate(-15deg);
    }
    70% {
        transform: rotate(10deg);
    }
    85% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
