@font-face {
    font-display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Montserrat/montserrat-v26-latin-700.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato/lato-v24-latin-regular.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/Lato/lato-v24-latin-italic.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Lato/lato-v24-latin-700.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Lato/lato-v24-latin-700italic.woff2") format("woff2");
}

@layer components {
    .typo-h1,
    .wysiwyg h1 {
        @apply font-title font-bold text-h1 lg:text-lg-h1;
    }

    .typo-h2,
    .wysiwyg h2 {
        @apply font-title font-bold text-h2 lg:text-lg-h2;
    }

    .typo-h3,
    .wysiwyg h3 {
        @apply font-base font-bold text-h3 lg:text-lg-h3;
    }

    .typo-h4,
    .wysiwyg h4 {
        @apply font-base font-bold text-h4 lg:text-lg-h4;
    }

    .typo-title-caps {
        @apply font-base uppercase text-title-caps lg:text-lg-title-caps;
    }

    .typo-caption,
    .wysiwyg table caption {
        @apply font-base text-caption lg:text-lg-caption;
    }

    .typo-body {
        @apply font-base text-body lg:text-lg-body;
    }

    .typo-body-bold {
        @apply typo-body font-bold;
    }

    .typo-display,
    .wysiwyg blockquote {
        @apply font-base text-display lg:text-lg-display italic;
    }

    .typo-notice,
    .wysiwyg .typo-advice {
        @apply font-base italic text-notice text-grey-800 font-normal;
    }
}
