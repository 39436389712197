@layer components {
    .big-btn {
        @apply typo-body-bold rounded-lg px-5 py-4;
    }

    .btn {
        @apply typo-body-bold rounded-lg px-4 py-2 flex items-center;
    }

    .icon-btn {
        @apply rounded-lg p-1 flex justify-center items-center;
    }

    .small-icon-btn {
        @apply flex items-center justify-center w-8 h-8 rounded-lg;
    }

    .circle-btn {
        @apply rounded-full p-1;
    }

    .btn-counter {
        @apply ml-2 bg-black rounded-full typo-caption text-white flex items-center justify-center font-normal;
        min-height: 25px;
        min-width: 25px;
    }

    .btn-mobile-counter {
        @apply lg:hidden ml-2 bg-black rounded-full typo-caption text-white flex items-center justify-center;
        min-height: 25px;
        min-width: 25px;
    }

    .primary-btn {
        @apply bg-primary text-white lg:hocus:bg-hover_primary;
    }

    .primary-btn svg path {
        @apply fill-white;
    }

    .secondary-btn {
        @apply bg-secondary lg:hocus:bg-hover_secondary text-black;
    }

    .secondary-btn svg path {
        @apply fill-black;
    }

    .primary-outline-btn {
        @apply text-primary border border-primary lg:hocus:border-hover_primary hocus:bg-primary hocus:text-white;
    }

    .primary-outline-btn svg path {
        @apply fill-primary;
    }

    .white-outline-btn {
        @apply text-white border border-white hocus:bg-white hocus:text-primary;
    }

    .white-outline-btn svg path {
        @apply fill-white;
    }

    .blue-btn {
        @apply bg-blue-100 lg:hocus:bg-blue-200;
    }
}
