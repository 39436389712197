input[type="range"]::-webkit-slider-thumb {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    -webkit-appearance: none;
    appearance: none;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    border-color: rgb(246 246 246 / var(--tw-border-opacity));
    border-radius: 9999px;
    border-style: solid;
    border-width: 5px;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
}

input[type="range"]::-moz-range-thumb {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    -moz-appearance: none;
    appearance: none;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    border-color: rgb(246 246 246 / var(--tw-border-opacity));
    border-radius: 9999px;
    border-style: solid;
    border-width: 5px;
    cursor: pointer;
    height: 1rem;
    margin-top: -14px;
    width: 1rem;
}

input[type="range"]:hover::-webkit-slider-thumb {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

input[type="range"]:hover::-moz-range-thumb {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
