@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "pills.css";
@import "scrollbar.css";
@import "typography.css";
@import "buttons.css";
@import "wysiwyg.css";
@import "blocks.css";
@import "switch.css";
@import "animation.css";
@import "slider.css";
@import "modal.css";

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@layer components {
    .container-with-padding {
        @apply px-4 lg:px-8;
    }
}

summary {
    list-style-type: none;
}

summary.with-arrow {
    list-style-type: disclosure-closed;
}

/* Needed until Safari fixes https://bugs.webkit.org/show_bug.cgi?id=157323 */
summary::-webkit-details-marker {
    display: none;
}

summary.with-arrow::-webkit-details-marker,
summary.with-arrow::marker {
    display: inline-block;
}

@layer utilities {
    .fade-in {
        @apply opacity-0 translate-y-8 transition-all duration-700 ease-out;
    }

    .fade-in.visible {
        @apply opacity-100 translate-y-0;
    }
}
