body ::-webkit-scrollbar {
    width: 10px;
}

body ::-webkit-scrollbar-thumb {
    background-color: theme("colors.primary_light");
    border-radius: 5px;
}

body ::-webkit-scrollbar-thumb:hover {
    background-color: theme("colors.primary");
    cursor: pointer;
}
