@layer components {
    label.switch input[type="checkbox"] {
        @apply w-11 h-0 cursor-pointer inline-block;
        @apply focus:outline-0 dark:focus:outline-0;
        @apply border-0 dark:border-0;
        @apply focus:ring-offset-transparent dark:focus:ring-offset-transparent;
        @apply focus:ring-transparent dark:focus:ring-transparent;
        @apply focus-within:ring-0 dark:focus-within:ring-0;
        @apply focus:shadow-none dark:focus:shadow-none;

        @apply after:absolute before:absolute;
        @apply after:top-0 before:top-0;
        @apply after:block before:inline-block;
        @apply before:rounded-full after:rounded-full;

        @apply after:content-[''] after:w-5 after:h-5 after:mt-0.5 after:ml-0.5;
        @apply after:shadow-md after:duration-100;

        @apply before:content-[''] before:w-10 before:h-6;
        @apply before:shadow-[inset_0_0_#000];

        @apply after:bg-white dark:after:bg-gray-50;
        @apply before:bg-gray-300 dark:before:bg-gray-600;
        @apply before:checked:bg-primary dark:before:checked:bg-primary;
        @apply checked:after:duration-300 checked:after:translate-x-4;

        @apply disabled:after:bg-opacity-75 disabled:cursor-not-allowed;
        @apply disabled:checked:before:bg-opacity-40;
    }

    label.switch input[type="checkbox"]::before {
        @apply lg:top-[15%];
    }

    label.switch input[type="checkbox"]::after {
        @apply lg:top-[15%];
    }
}
