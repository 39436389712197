@media (max-width: 1024px) {
    #modal-turbo-frame:modal {
        max-width: 100dvw;
        max-height: 100dvh;
        height: 100dvh;
    }
}

#modal-turbo-frame::backdrop {
    background-color: rgba(0, 0, 0, 0.3);
}
